import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Wait from './Wait';
require('es6-promise').polyfill();
require('isomorphic-fetch');

class ButtonOrderNext extends React.Component {

    state = {
      waiting: false,
      message: null
    }

  render() {
    if (this.state.waiting) {
      return <Wait withoutText={true} classnames={"bg-primary mb-0 relative mt-0 mh-200"}/>;
    }
    
    return (
        <React.Fragment>
          <p className={this.props.elClassname}>{this.props.elText}</p> 
           
            <Row className={"bg-primary mx-0 px-0 pt-4 pb-5"}>
              <Col lg={12} className={"text-center pink"}>
              { this.props.nextLessonAccessKey 
              ?
              <a href={"/" + this.props.nextLessonAccessKey}>
                      <Button variant="pink" style={{maxWidth: "200px"}}>Następny krok</Button>
                    </a>
              : null
              }
              </Col>
            </Row>
        </React.Fragment>
      )
  }
}

export default ButtonOrderNext;
import React from 'react';
import AppNavbar from './components/AppNavbar';
import Menu from './components/Menu';
import Footer from './components/Footer';
import Wait from './components/Wait';
import { API_BASE_URL } from './utils/constants';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Scrollbar from 'react-scrollbars-custom';
import ArticleContainer from './components/ArticleContainer';
import {Helmet} from "react-helmet";
import "babel-polyfill";
require('es6-promise').polyfill();
require('isomorphic-fetch');

class App extends React.Component {

  state = {
    keyaccess: null,
    list: null,
    waiting: true,
    initiated: false,
    pathname: this.props.location.pathname,
    selectedStep: null
  }

  getKeyAccess = () => {
    if (this.props.match.params.k && ["projekt", "kontakt"].indexOf(this.props.match.params.k) === -1) {
      return this.props.match.params.k;
    }

    return window.sessionStorage.getItem("keyaccess")
  }

  refreshWhole = () => {    
    let keyaccess = this.state.keyaccess;

    if (!keyaccess) {
      this.setState({
        waiting: false
      });
      return;
    }
    
    fetch(API_BASE_URL + "/access/" + keyaccess)
      .then(res => res.json())
      .then(json => {
        
        if (json.status === 200) {          
          window.sessionStorage.setItem("keyaccess", json.obj.firstLessonKeyAccess);
          this.setState({
            waiting: false,
            list: json.obj.list
          });
        } else {
          this.setState({
            waiting: false
          });
        }
      })
      .catch((error) => {
        
        window.location = "https://teleterapia.pl";
        return (<Wait />);
      });
  }

  componentDidMount() {
    this.setState({
      keyaccess:  this.getKeyAccess()
    }, () => {
      this.refreshWhole();
    });    
  }

  getCurrentStep = () => {
    return this.state.selectedStep || this.state.list.filter(l => l.access === this.state.keyaccess)[0].step;
  }

  menuClick = (step) => {
    this.setState({
      selectedStep: step
    })
  }

  getSiteTitle = () => {
    let step = this.state.selectedStep || this.state.list.filter(l => l.access === this.state.keyaccess)[0].step;
    let lesson = this.state.list.filter(l => l.step === step)[0];

    return "Teleterapia.pl - Lekcja " + step + ": " + lesson.title;
  }

  render() {

    if (this.state.waiting) {
      return (<Wait />);
    }

    if (!this.state.list) {
      window.location = "https://teleterapia.pl";
      return (<Wait />);
    }
    this.getSiteTitle()
    return (
      <React.Fragment>
        <Helmet>          
          <title>{this.getSiteTitle()}</title>
        </Helmet>
        <AppNavbar list={this.state.list} pathname={this.state.pathname}/>
          <Container id="wrapper">
            <Row >
              {
                ['/projekt', '/kontakt'].indexOf(this.state.pathname) === -1 ? 
                  <Col lg={2} className="text-left menu d-none d-lg-block">
                    <Scrollbar scrollTop={this.getCurrentStep() <= 8 ? 0 : 1000} className={"scrollbar"}>
                        <Menu onClick={this.menuClick} list={this.state.list}/>
                      </Scrollbar>
                  </Col>
                :
                null
              }
              
              <Col lg={['/projekt', '/kontakt'].indexOf(this.state.pathname) === -1 ? 10 : 12} md={12}>
                <Scrollbar className={"scrollbar"}>
                  <main>
                    <ArticleContainer className={"d-sm-block d-md-block d-lg-none d-xl-none"}
                        refreshWhole={this.refreshWhole} keyaccess={this.props.match.params.k}/>  
                  </main>
                </Scrollbar>             
              </Col>
            </Row>
          </Container>
        <Footer />
      </React.Fragment>
    );
  }
}

export default App;
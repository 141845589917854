import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../resources/css/step15.css';
import classnames from 'classnames';
import Button from 'react-bootstrap/Button';
import { FaCheck } from 'react-icons/fa';
import { MdClose } from 'react-icons/md'



class Step15 extends React.Component {

  state = {
    classes: {},
    checked: false,
    textAlert: null,
    c: 0
  }

  checkAnswers = () => {
    let classes = this.state.classes;
    Object.keys(classes).filter(c => classes[c] === "checked").map(c => {
      let question = c.substring(c.indexOf("question")).replace("question", "correct");
      if (c.indexOf(this.props.params[question]) > -1) {
        classes[c] = "good";
      } else {
        classes[c] = "bad";
      }
      return null;
    });

    let textAlert = null;
    let correctAnswers = Object.keys(classes).filter(c => classes[c] === "good").length;
    let c = this.state.c + 1;
    if (correctAnswers === 4) {
      if (c <= 1) {
        textAlert = "Gratulacje! Udało Ci się opanować wiedzę na temat miejsc, w których możemy otrzymać pomoc, gdy zmagamy się z trudnościami psychicznymi.";
      } else {
        textAlert = "Gratulacje! Wiesz już, gdzie szukać pomocy w trudnych sytuacjach życiowych.";
      }
    } else if (correctAnswers > 0) {
      textAlert = "Odpowiedziałeś poprawnie na część pytań, spróbuj poprawić błędne odpowiedzi";
    } else {
      textAlert = "Twoje odpowiedzi nie są prawidłowe, spróbuj ponownie wybrać właściwe odpowiedzi";
    }

    this.setState({
      classes: classes,
      textAlert: textAlert,
      c: c
    })
  }

  selectAnswer = (answer) => {
    let classes = this.state.classes;
    let question = answer.substring(answer.indexOf("question"));
    if (!classes[answer]) {
      if (Object.keys(classes).filter(a => a.indexOf(question) > -1).filter(a => classes[a] === "good").length === 1) {
        return null;
      }
      Object.keys(classes).filter(a => a.indexOf(question) > -1).map(a => {
        if (classes[a] === "checked") {
          classes[a] = null;
        }
        return null;
      });
      classes[answer] = "checked";
    }

    this.setState({
      classes: classes
    });
  }

  render() {
    return (
        <Container className={"exercise exercise15 my-5 px-5"}>
          <Row>
            <Col className={"title mb-1"}>
              Ćwiczenie
            </Col>
          </Row>
          <Row>
            <Col className={"subtitle"}>
              { this.props.params.title }
            </Col>
          </Row>
          <Row className={"mb-3 text-left"}>
            <Col>
              {
                Object.keys(this.props.params).filter(p => p.indexOf("question") === 0).sort().map(p => {
                  return (
                    <React.Fragment key={p}>
                      <div className={"question"}><span>{ this.props.params[p] }</span></div>
                      { Object.keys(this.props.params).filter(q => q.indexOf("answer") === 0 && q.indexOf(p) > -1).sort().map(q => {
                          return <div 
                            key={q}
                            onClick={() => this.selectAnswer(q)}
                            className={classnames("answer", this.state.classes[q])}
                          >
                          <span>{ this.props.params[q] }</span>
                          {
                            this.state.classes[q] === "good" 
                          ? <div className={"icon"}><FaCheck strokeWidth={3}></FaCheck></div> 
                          : (this.state.classes[q] === "bad" 
                            ? <div className={"icon"}><MdClose strokeWidth={3}></MdClose></div> 
                            : null)
                          }
                          </div>
                      })
                      }
                    </React.Fragment>
                  )
                })
              }              
              <div className={"text-center"}>
                <Button onClick={() => this.checkAnswers() }>Sprawdź</Button>
                { this.state.textAlert ? <span className={"alert"}>{this.state.textAlert}</span> : null }
              </div>
            </Col>
          </Row>
        </Container>
    );
  }
}

export default Step15;
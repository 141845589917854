import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../resources/css/step8.css';


class Step8 extends React.Component {

  getText = () => {
    let data = this.props.params.text.split("{link}");
    let data2 = [];
    data2.push(data[0]);
    data2.push(<a key={1} className="uppercase" target="_blank" href={this.props.params.link}>tutaj</a>);
    data2.push(data[1]);
    return data2;
  }


  render() {
    return (
        <Container className={"exercise exercise8 my-5 px-5"}>
        <Row>
            <Col className={"title mb-1"}>
              Ćwiczenie
            </Col>
          </Row>
          <Row>
            <Col className={"subtitle"}>
              { this.props.params.title }
            </Col>
          </Row>
          <Row>
            <img src={this.props.params.image} alt="img" />
            <span className={"pt-5"}> {this.getText()} </span>
          </Row>
        </Container>
    );
  }
}

export default Step8;
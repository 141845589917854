import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Draggable from 'react-draggable';

class Range extends React.Component {

    state = {
        width: 10,
        bestAnswerMin: 0,
        bestAnswerMax: 0
      };

      componentDidMount() {
        let ranges = {};
        let min = 0;
        let bestAnswerMin, bestAnswerMax;

        for (var i = 0; i < this.props.labels.length; i++) {
            let l = this.props.labels[i];
            ranges[l] = {};
            ranges[l].min = min;
            ranges[l].max = min + 100 / this.props.labels.length;
            min = ranges[l].max;

            if (this.props.answers.indexOf(l) > -1) {
                if (bestAnswerMin === undefined || bestAnswerMin > ranges[l].min) {
                    bestAnswerMin = ranges[l].min;
                }
                if (bestAnswerMax === undefined || bestAnswerMax < ranges[l].max) {
                    bestAnswerMax = ranges[l].max;
                }
            }
        }
        
        this.setState({
            bestAnswerMin: bestAnswerMin,
            bestAnswerMax: bestAnswerMax,
            ranges: ranges
        });
        
      }

 render() {
    return (
        <Container className={"range-container"}>
            <Row className={"mx-0 relative"}>
                <div className={"range-tooltip"}>{this.props.tooltip}</div>
                <div className={"range-point"}>
                    <Draggable bounds="parent" axis="x"
                    onDrag={(a, b)=> {
                        let w = b.node.parentNode.offsetWidth - 24;
                        let p = b.x / w * 100;

                        this.setState({width: b.x});

                        if (p >= this.state.bestAnswerMin && p <= this.state.bestAnswerMax) {
                            this.props.changeAnswer(this.props.type, 0);
                        } else if (p < this.state.bestAnswerMin) {
                            this.props.changeAnswer(this.props.type, this.state.bestAnswerMin - p);
                        } else {
                            this.props.changeAnswer(this.props.type, p - this.state.bestAnswerMax);
                        }
                        }}
                    defaultPosition={{x: this.state.width, y: 0}}
                    >
                        <div className={"range-marker"}></div>
                    </Draggable>
                </div>
                <div className={"range-bar"}>
                    <div className={"range-filled"} style={{"width": this.state.width + "px"}}></div>
                </div>
                <div className={"range-labels"}>
                    {this.props.labels.map(l => 
                        <div className={"label"} style={{width: 95 / this.props.labels.length + "%",}} key={l}>{l}</div>
                    )}
                </div>
            </Row>
         
        </Container>
    );
  }
}

export default Range;
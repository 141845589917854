import React from 'react';
import Col from 'react-bootstrap/Col';
import {Helmet} from "react-helmet";


function Project() {
  return (
    <Col lg={12} className={"py-5"}>
        <Helmet>          
          <title>Teleterapia.pl - O projekcie</title>
        </Helmet>
      <h2>Projekt SIMON</h2>
      <p className={"px-2 text-justify"}>
      Każdego dnia średnio szesnaścioro Polaków odbiera sobie życie, nie mogąc  znieść cierpienia wywołanego przez depresję. To choroba, która dotyka już ponad 1,5 mln Polaków. W Ati Labs zajmujemy się wykorzystaniem nowoczesnych technologii w obszarze zdrowia psychicznego i wierzymy, że możliwe jest opracowanie mobilnej aplikacji pomagającej osobom cierpiącym z powodu objawów depresji. Realizujemy projekt SIMON: system do komputerowego wspierania osób zmagających się z depresją. Powodzenie projektu zagwarantuje, że SIMON będzie oferował profesjonalne wsparcie psychologiczne, oparte o techniki psychoterapii poznawczo-behawioralnej, dostępne dla każdego, za pomocą kilku kliknięć, na własnym telefonie.
      </p>
      <p className={"px-2 text-justify"}>
      Dla naszego zespołu SIMON to nie tylko projekt naukowy i biznesowy, ale także coś osobistego – wiemy, czym jest depresja i chcemy pomóc osobom, które nie mogą pozwolić sobie na drogie leczenie psychologiczne lub mają opory przed wizytą u psychoterapeuty, czekają na refundowane leczenie lub są w jego trakcie.

      Zapraszamy do współpracy wszystkich zainteresowanych tematem depresji – zarówno specjalistów, jak i osoby, które mają osobiste doświadczenie z tą chorobą. Jeśli masz pomysł, propozycję, pytanie – napisz do nas i razem stwórzmy skuteczny program do terapii.
      </p>

      <h2 className={"mt-5"}>Komputerowe wsparcie psychologiczne</h2>
      <p className={"px-2 text-justify"}>
      Komputerowe wsparcie psychologiczne polega na zastosowaniu aplikacji lub programu komputerowego jako dodatku lub alternatywy dla tradycyjnych spotkań z psychologiem, a także jako uzupełnienie leczenia farmakologicznego. W jaki sposób komputerowe wsparcie psychologiczne może pomóc osobom cierpiącym z powodu depresji? Należy zdać sobie sprawę, że proces zdrowienia to w dużej mierze proces nauki i zmiany samego pacjenta. Nurt poznawczo-behawioralny, będący jednym z najskuteczniejszych podejść terapeutycznych, uczy pacjenta związku pomiędzy myślami, uczuciami i zachowaniem. Sam proces takiej terapii przypomina kurs – pacjent poznaje teorię i wykonuje “zadania domowe”, na przykład prowadząc samoobserwację lub próbując zmienić swoje myśli i zachowanie. Właśnie ten aspekt poznawczy może być skutecznie realizowany przez aplikację, która będzie stanowić źródło wiedzy i pomoże wykonywać “zadania domowe”. Co więcej, dostępne obecnie na rynku podobne aplikacje (np. MoodGym), prezentują treści w formie interaktywnej książki, co dla wielu potencjalnych użytkowników jest sposobem za mało angażującym, zbyt obszernym i ujmującym materiał w niezrozumiały sposób. SIMON ma odpowiadać na potrzeby użytkowników ciekawą, przystępną formą, wspomagającą zaangażowanie w proces.
      </p>
    </Col>
  );
}

export default Project;

import React from 'react';
import {BrowserRouter as Router,Route,Switch,Redirect} from 'react-router-dom';
import Article from './Article';
import Project from '../components/Project';
import Contact from '../components/Contact';


class ArticleContainer extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    return this.props.keyaccess !== nextProps.keyaccess;
  }

  render() {
    return (
        <Router>        
          <Switch>
              <Route
                  path="/atilabs"
                  component={ Redirect }
                  loc="http://teleterapia.pl"
              />  
              <Route path="/projekt" component={ Project }/>
              <Route path="/kontakt" component={ Contact }/>
              <Route path="/:k" component={(routerProps) =>  
                    <Article refreshWhole={this.props.refreshWhole} keyaccess={this.props.keyaccess}/>
                  }>       
              </Route>
              <Redirect to="/" />        
          </Switch>
        </Router> 
    );
  }
}

export default ArticleContainer;
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../resources/css/step6.css';
import classnames from 'classnames';
import { FaCheck } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';


class Step6 extends React.Component {

  state = {
    answers: {}
  }

  setAnswer = (question, answer) => {
    let answers = this.state.answers;
    answers[question] = answer;

    this.setState({
      answers: answers
    });
  }

  getAnswerClass = (question, type) => {
    if (this.state.answers[question] === undefined) {
      return null;
    }
    if (type.toString() !== this.state.answers[question].toString()) {
      return null;
    }
    if (this.props.params[question.replace('question', 'answer')].toString() === this.state.answers[question].toString()) {
      return "correct";
    } else {
      return "bad";
    }
  }

  getAnswerIcon = (question, type) => {
    if (this.state.answers[question] === undefined) {
      return null;
    }
    if (type.toString() !== this.state.answers[question].toString()) {
      return null;
    }
    if (this.props.params[question.replace('question', 'answer')].toString() === this.state.answers[question].toString()) {
      return <div className={"icon"}><FaCheck strokeWidth={3}></FaCheck></div>;
    } else {
      return <div className={"icon"}><MdClose strokeWidth={3}></MdClose></div>;
    }    
  }

  render() {
    return (
        <Container className={"exercise exercise6 my-5 px-5"}>
          <Row>
            <Col className={"title mb-1"}>
              Ćwiczenie
            </Col>
          </Row>
          <Row className={"mb-2"}>
            <Col className={"subtitle"}>
              { this.props.params.title }
            </Col>
          </Row>
          {Object.keys(this.props.params).filter(k => k.indexOf("question") === 0).map(q => 
            <Row className={"py-3 mrow"} key={q}>
              <Col lg={8} className={"px-3"}><div className={"question"}>{this.props.params[q]}</div></Col>
              <Col lg={2} sm={6} className={""}>
                <div onClick={() => {this.setAnswer(q, true)}} 
                  className={classnames("answer", "ml-20", (this.getAnswerClass(q, true)))}>
                    {this.getAnswerIcon(q, true)}
                  PRAWDA
                </div>
              </Col>
              <Col lg={2} sm={6} className={""}>
                <div onClick={() => {this.setAnswer(q, false)}} 
                  className = {classnames("answer", "mr-20", (this.getAnswerClass(q, false)))}>
                    {this.getAnswerIcon(q, false)}
                  FAŁSZ
                </div>
              </Col>
              <Col lg={12} className={"mt-2 mb-4 mtooltip"} style={{display: (this.state.answers[q] === true || this.state.answers[q] === false ? "inline-block": "none")}} dangerouslySetInnerHTML={ { __html: this.props.params[q.replace('question', 'tooltip')] }}></Col>
            </Row>
          )}
        </Container>
    );
  }
}

export default Step6;
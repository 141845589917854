import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../resources/css/step7.css';
import Select, { components } from 'react-select';
import classnames from 'classnames';
import { FaLongArrowAltDown, FaArrowsAltH, FaExpandArrowsAlt, FaArrowsAltV } from 'react-icons/fa';
import { FaCheck } from 'react-icons/fa';
import { MdClose } from 'react-icons/md'


class Step7 extends React.Component {

  state = {
    userAnswers: {}
  }

  getSelectComponent = (type) => {
    return (
      <Select 
        placeholder="Wybierz..."
        isSearchable={false}
        components={{ 
          IndicatorsContainer: () => null,
          Control: (props) => 
            <div style={{height: "100%"}}>
              { this.getAnswerIcon(type) }
              <components.Control {...props} />
            </div>
        
        }}
        onChange={ (a, b) => {
          let ua = this.state.userAnswers;
          ua[type] = a.value;
          this.setState({
            userAnswers: ua
          });
        }}
        styles={{
          container: (provided) => ({
            ...provided,
            width: "100%"
          }),
          control: () => ({
            border: "none !important",
            borderRadius: "10px",
            backgroundColor: this.getBackgroundColor(type),
            color: "#3c3c3b",
            width: "80%",
            margin: "auto",
            height:"100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }),
          singleValue: () => ({
            whiteSpace: "normal",
            margin: "auto"
          }),
          placeholder: () => ({
            margin: "auto"
          }),
          valueContainer: () => ({
            display: "flex",
            padding: "10px 15px",
            fontSize: "12px",
            lineHeight: "14px",
            fontWeight: "bold",
            textAlign: "center"
          }),
          menu: (provided) => ({
            ...provided,
            borderRadius: "15px",
            overflow: "hidden"
          }),
          menuList: () => ({
            backgroundColor: "#fff9dc",
            fontSize: "12px"
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? "#f3d717" : "#fff9dc",
            color: "inherit",
            border: "none !important",
            ":active": { backgroundColor: "#f3d717" }
          })
        }}
        options={[
          { value: 'option1', label: this.props.params.option1 },
          { value: 'option2', label: this.props.params.option2 },
          { value: 'option3', label: this.props.params.option3 },
          { value: 'option4', label: this.props.params.option4 }
        ]}>
      </Select>
    );
  }

  getAnswerIcon = (type) => {const correctAnswers = {
      mysli: "option4",
      emocje: "option2",
      zachowania: "option3",
      reakcje: "option1"
    };

    if (this.state.userAnswers[type] === undefined) {
      return null;
    }

    if (this.state.userAnswers[type] === correctAnswers[type]) {
      return <div className={"icon"}><FaCheck strokeWidth={3}></FaCheck></div>;
    } else {
      return <div className={"icon"}><MdClose strokeWidth={3}></MdClose></div>;
    }
  }

  getBackgroundColor = (type) => {
    const correctAnswers = {
      mysli: "option4",
      emocje: "option2",
      zachowania: "option3",
      reakcje: "option1"
    };

    if (this.state.userAnswers[type] === undefined) {
      return "white";
    }

    if (this.state.userAnswers[type] === correctAnswers[type]) {
      return "#f3d717";
    } else {
      return "#868686";
    }
  }

  getAnswer = () => {
    const correctAnswers = {
      mysli: "option4",
      emocje: "option2",
      zachowania: "option3",
      reakcje: "option1"
    };

    for (var k in correctAnswers) {
      if (correctAnswers[k] !== this.state.userAnswers[k]) {
        return "";
      }
    }
    return this.props.params.bottomButton;
  }

  render() {

    return (
        <Container className={"exercise exercise7 my-5 px-5"}>
        <Row>
            <Col className={"title mb-1"}>
              Ćwiczenie
            </Col>
          </Row>
          <Row>
            <Col className={"subtitle"}>
              { this.props.params.title }
            </Col>
          </Row>
          <Row className={"mt-5 mb-3 mrow"}>
             <Container style={{maxWidth: "600px"}}>
              <Row><Col lg={12} sm={12} className={"text-center"}><h1>{this.props.params.topTitle}</h1></Col></Row>
              <Row><Col lg={12} sm={12} className={"text-center subtitle"}>{this.props.params.topSubtitle}</Col></Row>
              <Row><Col lg={12} sm={12} className={"text-center"}><FaLongArrowAltDown color={"#e6197f"} size={"40px"} style={{padding: "10px"}}/></Col></Row>
              <Row><Col lg={12} sm={12} className={"text-center"}><div className={"pink"}>{this.props.params.topButton}</div></Col></Row>
              <Row className={"mt-4"}>
                <Col lg={5} sm={5} className={"ml-auto px-0 py-0"}>
                  <div className='box'> 
                      <div className='content top-left flex-end'>
                        <div style={{width: "100%"}}>
                          <div className={"header to-right"}><h2>{this.props.params.mysliTitle}</h2></div>
                          <div className={"title to-right"}><h3 className={"px-3"}>{this.props.params.mysliSubtitle}</h3></div>
                          <div className={classnames("select")}>{ this.getSelectComponent("mysli") }</div>    
                        </div>
                      </div> 
                  </div>
                </Col>
                <Col lg={1} sm={2} className={"my-auto px-0 py-0"}><FaArrowsAltH color={"#e6197f"} size={"100%"} style={{padding: "10px"}}/></Col>
                <Col lg={5} sm={5} className={"mr-auto px-0 py-0"}>
                  <div className='box'> 
                      <div className='content top-right flex-end'>
                        <div style={{width: "100%"}}>
                          <div className={"header to-left"}><h2>{this.props.params.emocjeTitle}</h2></div>
                          <div className={"title to-left"}><h3 className={"px-3"}>{this.props.params.emocjeSubtitle}</h3></div>
                          <div className={classnames("select")}>{ this.getSelectComponent("emocje") }</div>    
                        </div>
                      </div> 
                  </div>
                </Col>
              </Row>
              <Row style={{maxHeight: "40px"}}>
                <Col lg={5} sm={5} className={"ml-auto px-0 py-0"} style={{maxHeight: "40px"}}><FaArrowsAltV color={"#e6197f"} size={"100%"} style={{padding: "10px"}}/></Col>
                <Col lg={1} sm={2} className={"my-auto px-0 py-0"} style={{maxHeight: "40px"}}><FaExpandArrowsAlt color={"#e6197f"} size={"40px"} style={{padding: "10px"}}/></Col>
                <Col lg={5} sm={5} className={"mr-auto px-0 py-0"} style={{maxHeight: "40px"}}><FaArrowsAltV color={"#e6197f"} size={"100%"} style={{padding: "10px"}}/></Col>
              </Row>
              <Row className={"mb-4"}>
                <Col lg={5} sm={5} className={"ml-auto px-0 py-0"}>
                  <div className='box'> 
                      <div className='content bottom-left flex-start'>
                        <div style={{width: "100%"}}>
                          <div className={classnames("select")}>{ this.getSelectComponent("zachowania") }</div>    
                          <div className={"header to-right"}><h2>{this.props.params.zachowaniaTitle}</h2></div>
                          <div className={"title to-right"}><h3 className={"px-3"}>{this.props.params.zachowaniaSubtitle}</h3></div>
                        </div>
                      </div> 
                  </div>
                </Col>
                <Col lg={1} sm={2} className={"my-auto px-0 py-0"}><FaArrowsAltH color={"#e6197f"} size={"100%"} style={{padding: "10px"}}/></Col>
                <Col lg={5} sm={5} className={"mr-auto px-0 py-0"}>
                  <div className='box'> 
                      <div className='content bottom-right flex-start'>
                        <div style={{width: "100%"}}>
                          <div className={classnames("select")}>{ this.getSelectComponent("reakcje") }</div>                            
                          <div className={"header to-left"}><h2>{this.props.params.reakcjeTitle}</h2></div>
                          <div className={"title to-left"}><h3 className={"px-3"}>{this.props.params.reakcjeSubtitle}</h3></div>
                        </div>
                      </div> 
                  </div>
                </Col>
              </Row>
              <Row><Col lg={12} sm={12} className={"text-center"}><h1>{this.props.params.bottomTitle}</h1></Col></Row>
              <Row><Col lg={12} sm={12} className={"text-center"}>{this.props.params.bottomSubtitle}</Col></Row>
              <Row><Col lg={12} sm={12} className={"text-center"}><FaLongArrowAltDown color={"#e6197f"} size={"40px"} style={{padding: "10px"}}/></Col></Row>
              <Row><Col lg={12} sm={12} className={"text-center"}><div className={"pink"}>{ this.getAnswer() }</div></Col></Row>
             </Container>
          </Row>
        </Container>
    );
  }
}

export default Step7;
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../resources/css/step14.css';



class Step14 extends React.Component {

  render() {
    return (
        <Container className={"exercise exercise14 my-5 px-5"}>
        <Row>
            <Col className={"title mb-1"}>
              Ćwiczenie
            </Col>
          </Row>
          <Row>
            <Col className={"subtitle"}>
              { this.props.params.title }
            </Col>
          </Row>
          <Row className={"mt-3 mb-3"}>
            <Col>
            {
                Object.keys(this.props.params).filter(p => p.indexOf("block") === 0).sort().map(p => {
                  return (
                    <p className={"px-5"} key={p}
                    dangerouslySetInnerHTML={ { __html: this.props.params[p] } }></p>
                  )
                })
              }
            </Col>
          </Row>
        </Container>
    );
  }
}

export default Step14;
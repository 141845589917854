import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Step9Block from './Step9Block';
import '../resources/css/step9.css';
import classnames from 'classnames';


class Step9 extends React.Component {
  
  state = {
    selected: [],
    checked: false
  }

  onClick = (ind) => {
    if (this.state.checked || this.state.selected.length >= 3) {
      return;
    }
    
    var selected = this.state.selected;
    if (selected.indexOf(ind) === -1) {
      selected.push(ind);
    } else {
      const index = selected.indexOf(ind);
      selected.splice(index, 1);
    }
    this.setState({
      selected: selected
    });
  }

  render() {
    return (
        <Container className={"exercise exercise9 my-5 px-5"}>
        <Row>
            <Col className={"title mb-1"}>
              Ćwiczenie
            </Col>
          </Row>
          <Row>
            <Col className={"subtitle"}>
              { this.props.params.title }
            </Col>
          </Row>
          <Row className={"mt-5 mb-3 mrow"}>
              {
                Object.keys(this.props.params).sort().map(p => {
                  if (p.indexOf("optionTitle") === 0) {
                    return <Step9Block 
                      key={p} 
                      ind={p} 
                      selected={this.state.selected.indexOf(p) > -1} 
                      onClick={ this.onClick } 
                      title={this.props.params[p]} 
                      text={this.props.params[p.replace('Title', 'Text')]}
                    />
                  }
                  return null;
                })
              }
          </Row>
          <Row>
            <Col className={"text-center"}>
              { this.state.checked ? 
                <Button onClick={() => {this.setState({checked: false, selected: []})}}>ZRESETUJ</Button>
              :
                <Button onClick={() => {this.setState({checked: true})}}>ZATWIERDŹ</Button>
              }
            </Col>
          </Row>
          <Row className={classnames(this.state.checked ? "" : "d-none", "text-left mt-5")}>
            <Col lg={12}>
              <img style={{width: "100%"}} src={this.props.params.finalImage} alt="img" />
            </Col>
          </Row>
        </Container>
    );
  }
}

export default Step9;
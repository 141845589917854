import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import '../resources/css/step10.css';
import Modal from 'react-bootstrap/Modal';
import TextareaAutosize from 'react-textarea-autosize';


class Step10 extends React.Component {
  
  state = {
    show: false
  }

  render() {
    return (
        <Container className={"exercise exercise10 my-5 px-5"}>
        <Row>
            <Col className={"title mb-1"}>
              Ćwiczenie
            </Col>
          </Row>
          <Row>
            <Col className={"subtitle"}>
              { this.props.params.title } <span style={{cursor: "pointer", textDecoration: "underline", color: "#6f6f6e"}} onClick={() => this.props.viewKnowledgeExtension("r_listaemocji_r")}>{ this.props.params.title2 }</span>
            </Col>
          </Row>
          <Row className={"mt-3 mb-3 mTable"}>
              {
                Object.keys(this.props.params).filter(p => p.indexOf("situationFirst") === 0).sort().map(p => {
                  return (
                    <Col lg={12} key={p}>
                      <table className={"mTable quarter"}>
                        <thead>
                          <tr>
                            <th></th>
                            <th>{this.props.params["tableTh1"]}</th>
                            <th>{this.props.params["tableTh2"]}</th>
                            <th>{this.props.params["tableTh3"]}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style={{fontWeight: "bold"}}>{this.props.params["tableRowTitle1"]}</td>
                            <td>{this.props.params[p]}</td>
                            <td>{this.props.params[p.replace('situation', 'think')]}</td>
                            <td>{this.props.params[p.replace('situation', 'emotion')]}</td>
                          </tr>
                          <tr>
                            <td style={{fontWeight: "bold"}}>{this.props.params["tableRowTitle2"]}</td>
                            <td>{this.props.params[p.replace('situationFirst', 'situationSecond')]}</td>
                            <td><TextareaAutosize placeholder="Klinij by wypełnić..."></TextareaAutosize></td>
                            <td><TextareaAutosize placeholder="Klinij by wypełnić..."></TextareaAutosize></td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  )
                })
              }
              <Modal show={this.state.show} 
                onHide={() => this.setState({show: false})} 
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className={"modal"}
                >
                <Modal.Header closeButton>
                  <Modal.Title>{ "Przykładowa lista emocji" }</Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.props.params.emotions}</Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => this.setState({show: false})}>
                    Zamknij
                  </Button>
                </Modal.Footer>
              </Modal>
          </Row>
        </Container>
    );
  }
}

export default Step10;
import React from 'react';
import classnames from 'classnames';
import Col from 'react-bootstrap/Col';


class Step3Block extends React.Component {

    getClass = (checked, selected) => {
        if (checked) {
            if (selected) {
                return "good";
            } else {
                return "bad";
            }
        } else {
            if (selected) {
                return "selected"
            }
        }

        return null;
    }

  render() {
    return (
        <Col lg={12} sm={12} className={classnames("block px-1", this.getClass(this.props.checked, this.props.selected))} onClick={() => {this.props.onClick(this.props.ind)}}>
            <div>
                <span>{ this.props.text }</span>
            </div>            
        </Col>
    );
  }
}

export default Step3Block;
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../resources/css/step11.css';
import Draggable from 'react-draggable';
import classnames from 'classnames';
import { FaCheck } from 'react-icons/fa';



class Step11 extends React.Component {

  state = {
    items: []
  }

  componentDidMount() {
    let items = [];
    Object.keys(this.props.params).filter(p => p.indexOf("answer") === 0).reverse().map(p => {
      items.push({
        key: p,
        value: this.props.params[p],
        correct: ""
      });
      return null;
    });
    
    this.setState({
      items: items
    });
  }

 findWithAttr = (array, attr, value) => {
      for(var i = 0; i < array.length; i += 1) {
          if(array[i][attr] === value) {
              return i;
          }
      }
      return -1;
  }

  handleStop = (a, b, c, d) => {
    c.preventDefault();
    c.stopPropagation();

    try {
      let items = this.state.items;

      let blockH = 130;
      let steps = a.y / blockH + (a.y % blockH) / blockH;
      steps = steps > 0 ? Math.floor(steps) : Math.ceil(steps);
      if (steps >= items.length) steps = items.length - 1;
      else if (steps * -1 >= items.length) steps = -1 * (items.length - 1);
      
      let currentInd = this.findWithAttr(items, "key", b);
      let currentItem = items[currentInd];
      let newInd = currentInd + steps;
      let newItem = items[newInd];

      if (currentInd === -1 || newInd === -1) {
        return;
      }
      items[currentInd] = newItem;
      items[newInd] = currentItem;

      if (parseInt(currentItem.key.replace("answer", "")) === newInd + 1) {
        currentItem.class = "goodanswer";
      } else {
        currentItem.class = "";
      }

      if (parseInt(newItem.key.replace("answer", "")) === currentInd + 1) {
        newItem.class = "goodanswer";
      } else {
        newItem.class = "";
      }
      
      this.setState({
        items: items
      });
    } catch(e) {}
  }

  render() {
    return (
        <Container className={"exercise exercise11 my-5 px-5"}>
        <Row>
            <Col className={"title mb-1"}>
              Ćwiczenie
            </Col>
          </Row>
          <Row>
            <Col className={"subtitle mb-5"}>
              { this.props.params.title }
            </Col>
          </Row>
          <Row className={"mt-5 mb-3"} className="px-0 mmy-3">
            <Col lg={6} sm={6} className="px-0">
              <h1>{this.props.params.title1}</h1>
              {
                Object.keys(this.props.params).filter(p => p.indexOf("blockTitle") === 0).sort().map(p => {
                  return (
                    <div key={p} className="block">
                      <div style={{margin: "auto"}}>
                          <h2>{ this.props.params[p] }</h2>
                          <span>{ this.props.params[p.replace("blockTitle", "blockContent")] }</span>
                      </div>
                    </div>
                  )
                })
              }
            </Col>
            <Col lg={6} sm={6} className="px-0">
              <h1>{this.props.params.title2}</h1>
              {
                this.state.items.map(p => {
                  return (
                    <Draggable key={p.key} bounds="parent" axis="y"
                      onStop={(a,b) => this.handleStop(b, p.key, a, b)}
                      position={{x: 0, y: 0}}
                      >
                      <div className={classnames(p.class, "answer")}>
                          <span>{ p.value }</span>
                          {
                            (p.class === 'goodanswer' ? <div className={"icon"}><FaCheck strokeWidth={3}></FaCheck></div> : null)
                          }
                      </div>
                    </Draggable>
                    
                  )
                })
              }
            </Col>
          </Row>
        </Container>
    );
  }
}

export default Step11;
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../resources/css/step13.css';



class Step13 extends React.Component {

  render() {
    return (
        <Container className={"exercise exercise13 my-5 px-5"}>
        <Row>
            <Col className={"title mb-1"}>
              Ćwiczenie
            </Col>
          </Row>
          <Row>
            <Col className={"subtitle"}>
              { this.props.params.title }
            </Col>
          </Row>
          <Row className={"mt-3 mb-0"}>
            <Col>
              <img src={this.props.params.image} alt="img" />
              <span>{this.props.params.summary}</span>
            </Col>
          </Row>
        </Container>
    );
  }
}

export default Step13;
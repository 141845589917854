import React from 'react';
import classnames from 'classnames';
import Col from 'react-bootstrap/Col';


class Step9Block extends React.Component {

    getClass = (checked, selected) => {
        if (selected) {
            return "selected"
        }

        return null;
    }

  render() {
    return (
        <Col lg={12} sm={12} className={classnames("block px-1", this.getClass(this.props.checked, this.props.selected))} onClick={() => {this.props.onClick(this.props.ind)}}>
            <div>
                <h1>{ this.props.title }</h1>
                <span>{ this.props.text }</span>
            </div>            
        </Col>
    );
  }
}

export default Step9Block;
import React from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';



function Footer() {
  return (
    <footer>
        <Container>
            <div className="text-center">
              <span className={"icon d-none d-xl-inline"}><img alt="kontakt" src="/img/kontakt.svg"/></span>W przypadku znaczącego pogorszenia samopoczucia zadzwoń pod <b>numer alarmowy 112</b> 
              <Button className={"d-none d-xl-inline"} variant="white">więcej informacji</Button>
            </div>
        </Container>
    </footer>
  );
}

export default Footer;

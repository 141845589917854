import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../resources/css/step16.css';
import Button from 'react-bootstrap/Button';
import { API_BASE_URL } from '../utils/constants';


class Step16 extends React.Component {

  state = {
    ordered: false
  }

  orderFullVersion = (decision) => {
    fetch(API_BASE_URL + "/fullversion/" + this.props.logID + "/" + decision, {method: 'post'});
    
    this.setState({
      ordered: true
    });
  }

  render() {
    return (
        <Container className={"exercise exercise16 my-5 px-5"}>
          <Row className={"mt-5 mb-3 text-center"}>
            <Col>
              <div className={"alert"}>
                { Object.keys(this.props.params).filter(p => p.indexOf("text") === 0).sort().map(p => {
                  return <span key={p}>{this.props.params[p]}</span>
                }) }
                { !this.state.ordered ? 
                  (
                    <React.Fragment>
                      <span>Czy chciał(a)byś skorzystać w przyszłości z pełnej wersji produktu?</span>
                      <span>
                        <Button onClick={() => this.orderFullVersion(true)} className={"white"}>TAK</Button>
                        <Button onClick={() => this.orderFullVersion(false)} className={"pink"}>NIE</Button>
                      </span>
                    </React.Fragment>
                  )
                :
                <span>DZIĘKUJEMY!</span>
                }
                
              </div>
            </Col>
          </Row>
        </Container>
    );
  }
}

export default Step16;
import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';


class Menu extends React.Component {

  render() {
    return (
        <nav>
            { 
                this.props.list.sort((a, b) => a.step - b.step).map(el => 
                    <div className={classnames("item", (window.location.pathname === "/" + el.access ? "active" : (el.access ? "" : "disabled")))} key={el.step}>                        
                        {( el.access ?
                            <Link to={"./" + el.access} onClick={() => this.props.onClick(el.step)}>
                                <div>
                                    <h6>KROK {el.step}</h6>
                                    <span>{el.title}</span>      
                                </div>
                            </Link> 
                            :
                                <div>
                                    <h6>KROK {el.step}</h6>
                                    <span>{el.title}</span>      
                                </div>
                        )}          
                    </div>
                )
            }
        </nav>
    );
  }
}

export default Menu;
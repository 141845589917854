
import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import classnames from 'classnames';

class Wait extends React.Component {

  render() {
    return (
      <div className={classnames(this.props.classnames)} style={{width: "100%"}}>
          <div className="spinner" style={this.props.style}>
              <Spinner animation="grow" />
              {(!this.props.withoutText ? 
                <div>Proszę czekać...</div>
              :
                null
              )}
          </div>
      </div>
    );
  }
}

export default Wait;

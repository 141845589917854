import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../resources/css/step4.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={"modal"}
    >
      <Modal.Body>
      <video autoPlay="autoplay" style={{"width": "100%"}} controls>
        <source src={props.src} type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="pink2" style={{"margin": "auto"}} onClick={props.onHide}>Zamknij</Button>
      </Modal.Footer>
    </Modal>
  );
}

class Step4 extends React.Component {

  state = {
    modalShow: false,
    imgs: {}
  }

  componentDidMount() {
    let imgs = {};
    Object.keys(this.props.params).sort().map(p => {
      imgs[p] = this.props.params[p];
      return null;
    });
    this.setState({imgs: imgs});
  }
  

  render() {
    return (
        <Container className={"exercise exercise4 my-5 px-5"}>
        <Row>
            <Col className={"title mb-1"}>
              Ćwiczenie
            </Col>
          </Row>
          <Row>
            <Col className={"subtitle"}>
              { this.props.params.title }
            </Col>
          </Row>
          <Row className={"mt-5 mb-3 mrow"}>
          
            {
                Object.keys(this.props.params).sort().map(p => {
                  if (p.indexOf("videoposter") === 0) {
                    return (
                      <Col key={p}  lg={4} style={{margin: "auto"}}>
                        <img alt="poster" src={ this.state.imgs[p] } onClick={() => {this.setState({ modalShow: true, src: this.props.params[p.replace("poster", "src")] })}} 
                          onMouseOver={() => { 
                            let imgs = this.state.imgs;
                            imgs[p] = this.props.params[p].replace("opacity", "hover");
                            this.setState({imgs: imgs});
                          }}
                          onMouseOut={() => {
                            let imgs = this.state.imgs;
                            imgs[p] = this.props.params[p];
                            this.setState({imgs: imgs});
                          }}
                        />
                      </Col>
                    )
                  }
                  return null;
                })
              }

            <MyVerticallyCenteredModal
              show={this.state.modalShow}
              onHide={() => this.setState({modalShow: false})}
              src={ this.state.src }
            />
          
          </Row>
        </Container>
    );
  }
}

export default Step4;
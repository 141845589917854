import React from 'react';
import Col from 'react-bootstrap/Col';
import {Helmet} from "react-helmet";


function Contact() {
  return (
    <Col lg={12} className={"py-5"}>
        <Helmet>          
          <title>Teleterapia.pl - Kontakt</title>
        </Helmet>
      <h5 className={"mb-4"}>ATI Labs sp. z o.o.</h5>  

      <p className={"my-0"}>Siedziba: ul. Głogowska 31/33, 60-702 Poznań</p>
      <p className={"my-0"}>Biuro: ul. Zwierzyniecka 18 lok. 2 (III piętro), 60-814 Poznań</p>
      <p className={"my-0"}>Pokój badań: ul. Bukowińska 24D lok. 9 (II piętro), 02-703 Warszawa</p>

      <p className={"mb-0 mt-3"}>Rekrutacja pracowników: praca@atilabs.pl</p>
      <p className={"my-0"}>Rekrutacja osób do badań: badania@atilabs.pl</p>
      <p className={"my-0"}>Pozostałe sprawy: ati@atilabs.pl</p>
      <p className={"my-0"}>Tel. (godz. 9-15): 536 587 587, 796 161 684</p>

      <p className={"mb-0 mt-3"}>Zarząd: Marceli Kubik</p>
      <p className={"my-0"}>Prokurenci (pełnomocnicy): Natalia Wróblewicz, Wojciech Duchant</p>
      <p className={"my-0"}>KRS: 0000623743 REGON: 364730280 NIP: 7792443589</p>
      <p className={"my-0"}>Sąd Rejonowy Poznań – Nowe Miasto i Wilda w Poznaniu</p>
      <p className={"my-0"}>Kapitał zakładowy: 5000 PLN</p>

      <h5 className={"mb-4 mt-5"}>Gdzie szukać pomocy?</h5>
      <p className={"my-0"}>W razie wyraźnego pogorszenia samopoczucia lub silnego dyskomfortu w związku z udziałem w eksperymencie, prosimy o przerwanie kursu i zgłoszenie zakończenia badania w wiadomości e-mail lub telefonicznie (badania@atilabs.pl, tel: 796 161 684).</p>
      <p className={"my-0"}>Ponadto, jeśli w trakcie udziału w eksperymencie Twój nastrój ulegnie znacznemu obniżeniu, koniecznie zgłoś się po pomoc - możesz udać się do poradni zdrowia psychicznego w swoim miejscu zamieszkania lub skorzystać z odpłatnej pomocy psychologicznej/ psychoterapeutycznej.</p>
      <p className={"my-0"}>Jeśli doświadczysz myśli samobójczych lub agresywnych, zadzwoń pod telefon alarmowy 112. Możesz także udać się do najbliższego ośrodka interwencji kryzysowej lub szpitala psychiatrycznego.</p>
      <p className={"my-0"}>Do Twojej dyspozycji są również na darmowe linie wsparcia: 800 70 22 22 (Fundacja ITAKA, całodobowo)  i 116 123 (Instytut Psychologii Zdrowia, w godzinach 14-22).</p>

    </Col>
  );
}

export default Contact;

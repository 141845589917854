import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Menu from './Menu';
import Scrollbar from 'react-scrollbars-custom';
import {ReactComponent as Icon} from'../resources/img/logo.svg';


class AppNavbar extends React.Component {

  constructor(props) {
    super(props);
    this.refNavbar = React.createRef();
  }
  
  state = {
    expanded: false
  }

  render() {
    return (
      <header>
        <Navbar expanded={this.state.expanded} 
          onToggle={(expanded) => {this.setState({expanded: expanded})}} 
          id="mainNav" expand="lg"  sticky="top">
          <Container>
            <Navbar.Brand>
              <a href={"/" + window.sessionStorage.getItem("keyaccess")}>
                <Icon style={{ width: "130px"}} />
              </a>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                  <Nav.Link href={"/" + window.sessionStorage.getItem("keyaccess")}>program</Nav.Link>
                  <Nav.Link href="/projekt">o projekcie</Nav.Link>
                  <Nav.Link href="/kontakt">kontakt</Nav.Link>
                  {
                    ['/projekt', '/kontakt'].indexOf(this.props.pathname) === -1 ? 
                    <React.Fragment>
                      <hr className={"scrollbar d-sm-block d-md-block d-lg-none d-xl-none"}/>
                      <Scrollbar className={"scrollbar d-sm-block d-md-block d-lg-none d-xl-none"} style={{"minHeight": "200px"}}>
                        <Menu list={this.props.list} onClick={() => { this.setState({expanded: false })}}/>
                      </Scrollbar>
                    </React.Fragment>
                    :
                    null
                  }                  
                </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }
}

export default AppNavbar;

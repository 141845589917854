import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Step3Block from './Step3Block';
import '../resources/css/step3.css';


class Step3 extends React.Component {
  state = {
    selected: [],
    checked: false
  }

  onClick = (ind) => {
    if (this.state.checked) {
      return;
    }
    
    var selected = this.state.selected;
    if (selected.indexOf(ind) === -1) {
      selected.push(ind);
    } else {
      const index = selected.indexOf(ind);
      selected.splice(index, 1);
    }
    this.setState({
      selected: selected
    });
  }

  getAnswer = () => {
    let correctAnswers = this.state.selected.length;
    if (correctAnswers < 2) {
      return (
        <React.Fragment>
          <span className={"answer"}>Udzieliłeś { correctAnswers} poprawnych odpowiedzi.</span> 
          <span className={"answer tryagain"} onClick={() => this.setState({selected: [], checked: false})}> Spróbuj ponownie.</span>
        </React.Fragment>
      )
    } else if (correctAnswers < 12) {
      return (
        <React.Fragment>
          <span className={"answer"}>Całkiem nieźle, udzieliłeś { correctAnswers} poprawnych odpowiedzi.</span> 
          <span className={"answer tryagain"} onClick={() => this.setState({selected: [], checked: false})}> Spróbuj ponownie.</span>
        </React.Fragment>
      )
    } else if (correctAnswers === 12) {
      return (
        <React.Fragment>
          <span className={"answer"}>Brawo! Wszystkie odpowiedzi są właściwe.</span> 
        </React.Fragment>
      )
    }

    return null;
  }

  render() {
    return (
        <Container className={"exercise exercise3 my-5 px-5"}>
          <Row>
            <Col className={"title mb-1"}>
              Ćwiczenie
            </Col>
          </Row>
          <Row>
            <Col className={"subtitle"}>
              { this.props.params.title }
            </Col>
          </Row>
          <Row className={"mt-5 mb-3 mrow"}>
              {
                Object.keys(this.props.params).sort().map(p => {
                  if (p.indexOf("option") === 0) {
                    return <Step3Block key={p} ind={p} checked={ this.state.checked } selected={this.state.selected.indexOf(p) > -1} onClick={ this.onClick } text={this.props.params[p]} />
                  }
                  return null;
                })
              }
          </Row>
          <Row>
            <Col className={"text-center mt-5"}>
              {(
                this.state.checked ?
                  <React.Fragment>
                    { this.getAnswer() }
                  </React.Fragment>
                :
                  <Button onClick={() => {this.setState({checked: true})}}>SPRAWDŹ</Button>
              )}
              
            </Col>
          </Row>
        </Container>
    );
  }
}

export default Step3;
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Range from './Range';
import '../resources/css/step5.css';

class Step5 extends React.Component {
  state = {
    s: {
      sen: 42,
      dieta: 42,
      aktywnosc: 42,
      relacje: 42,
      alkohol: 42
    },
    sebastian: "sebastian_0"
  }

  changeAnswer = (t, answer) => {
    let s = this.state.s;
    s[t] = answer;
    
    this.setState({
      s: s
    }, () => {
      let sum = this.state.s.sen + this.state.s.dieta + this.state.s.aktywnosc + this.state.s.relacje + this.state.s.alkohol;
      if (sum >= 150) {
        this.setState({
          sebastian: "sebastian_0"
        });
      } else if (sum >= 70) {
        this.setState({
          sebastian: "sebastian_1"
        });
      } else if (sum >= 1) {
        this.setState({
          sebastian: "sebastian_2"
        });
      } else if (sum <= 1) {
        this.setState({
          sebastian: "sebastian_3"
        });
      }
    });

  }

  render() {
    return (
        <Container className={"exercise exercise5 my-5 px-5"}>
        <Row>
            <Col className={"title mb-1"}>
              Ćwiczenie
            </Col>
          </Row>
          <Row>
            <Col className={"subtitle"}>
              { this.props.params.title }
            </Col>
          </Row>
          <Row className={"mt-5 mb-3 mrow"}>
            <Col lg={4} sm={12} className={"picture"}>
              <img src={"/img/" + this.state.sebastian + ".png"} />
            </Col>
            <Col lg={8} sm={12}>
              <Row className={"range"}>
                <Col lg={2} sm={2}>
                  <img alt="icon" src={this.props.params["senIkona"]} />
                  <span>{this.props.params["senLabel"]}</span>
                </Col>
                <Col lg={10} sm={10}>
                  <Range
                    tooltip={this.props.params["senTooltip"]}
                    labels={Object.keys(this.props.params).filter(k => k.indexOf("senOption") === 0).map(k => this.props.params[k])}
                    answers={Object.keys(this.props.params).filter(k => k.indexOf("senAnswer") === 0).map(k => this.props.params[k])}
                    changeAnswer={this.changeAnswer}
                    type={"sen"}
                  />
                </Col>
              </Row>
              <Row className={"range"}>
                <Col lg={2} sm={2}>
                  <img alt="icon" src={this.props.params["dietaIkona"]} />
                  <span>{this.props.params["dietaLabel"]}</span>
                </Col>
                <Col lg={10} sm={10}>
                  <Range
                    tooltip={this.props.params["dietaTooltip"]}
                    labels={Object.keys(this.props.params).filter(k => k.indexOf("dietaOption") === 0).map(k => this.props.params[k])}
                    answers={Object.keys(this.props.params).filter(k => k.indexOf("dietaAnswer") === 0).map(k => this.props.params[k])}
                    changeAnswer={this.changeAnswer}
                    type={"dieta"}
                  />
                </Col>
              </Row>
              <Row className={"range"}>
                <Col lg={2} sm={2}>
                  <img alt="icon" src={this.props.params["aktywnoscIkona"]} />
                  <span>{this.props.params["aktywnoscLabel"]}</span>
                </Col>
                <Col lg={10} sm={10}>
                  <Range
                    tooltip={this.props.params["aktywnoscTooltip"]}
                    labels={Object.keys(this.props.params).filter(k => k.indexOf("aktywnoscOption") === 0).map(k => this.props.params[k])}
                    answers={Object.keys(this.props.params).filter(k => k.indexOf("aktywnoscAnswer") === 0).map(k => this.props.params[k])}
                    changeAnswer={this.changeAnswer}
                    type={"aktywnosc"}
                  />
                </Col>
              </Row>
              <Row className={"range"}>
                <Col lg={2} sm={2}>
                  <img alt="icon" src={this.props.params["relacjeIkona"]} />
                  <span>{this.props.params["relacjeLabel"]}</span>
                </Col>
                <Col lg={10} sm={10}>                  
                  <Range
                    tooltip={this.props.params["relacjeTooltip"]}
                    labels={Object.keys(this.props.params).filter(k => k.indexOf("relacjeOption") === 0).map(k => this.props.params[k])}
                    answers={Object.keys(this.props.params).filter(k => k.indexOf("relacjeAnswer") === 0).map(k => this.props.params[k])}
                    changeAnswer={this.changeAnswer}
                    type={"relacje"}
                  />
                </Col>
              </Row>
              <Row className={"range"}>
                <Col lg={2} sm={2}>
                  <img alt="icon" src={this.props.params["alkoholIkona"]} />
                  <span>{this.props.params["alkoholLabel"]}</span>
                </Col>
                <Col lg={10} sm={10}>
                  <Range
                    tooltip={this.props.params["alkoholTooltip"]}
                    labels={Object.keys(this.props.params).filter(k => k.indexOf("alkoholOption") === 0).map(k => this.props.params[k])}
                    answers={Object.keys(this.props.params).filter(k => k.indexOf("alkoholAnswer") === 0).map(k => this.props.params[k])}
                    changeAnswer={this.changeAnswer}
                    type={"alkohol"}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
    );
  }
}

export default Step5;
import React from 'react';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import classnames from 'classnames';
import IdleTimer from 'react-idle-timer'
import Wait from './Wait';
import { API_BASE_URL } from '../utils/constants';
import ButtonOrderNext from './ButtonOrderNext';
import Step3 from '../exercises/Step3';
import Step4 from '../exercises/Step4';
import Step5 from '../exercises/Step5';
import Step6 from '../exercises/Step6';
import Step7 from '../exercises/Step7';
import Step8 from '../exercises/Step8';
import Step9 from '../exercises/Step9';
import Step10 from '../exercises/Step10';
import Step11 from '../exercises/Step11';
import Step12 from '../exercises/Step12';
import Step13 from '../exercises/Step13';
import Step14 from '../exercises/Step14';
import Step15 from '../exercises/Step15';
import Step16 from '../exercises/Step16';
require('es6-promise').polyfill();
require('isomorphic-fetch');


class Article extends React.Component {

  constructor(props) {
    super(props)
    this.idleTimer = null
    this.onAction = this._onAction.bind(this);
  }

  state = {
    waiting: true,
    keyaccess: null,
    logID: null,
    title: null,
    content: null,
    ignoreFirstOnAction: true,
    list: [],
    nextLessonActivateDate: null,
    params: null,
    modal: {
      show: false
    }
  }

  _onAction(e) {
    if (this.state.ignoreFirstOnAction) {
      this.setState({
        ignoreFirstOnAction: false
      });
      return;
    }

    fetch(API_BASE_URL + "/log/" + this.state.logID, {method: 'post'});
  }

  handleModalClose = () => {
    let modal = this.state.modal;
    modal.show = false;
    this.setState({modal: modal});
  }

  componentDidMount (props) {
    setTimeout(()=> {
      let keyaccess = this.props.keyaccess || window.sessionStorage.getItem("keyaccess");

      fetch(API_BASE_URL + "/lesson/" + keyaccess)
        .then(res => res.json())
        .then(json => {
          
          if (json.status === 200) {
            this.setState({
              waiting: false,
              logID: json.obj.logID,
              title: json.obj.title,
              content: json.obj.content,
              nextLessonActivateDate: json.obj.nextLessonActivateDate,
              nextLessonAccessKey: json.obj.nextLessonAccessKey,
              params: json.obj.params
            });
          } else {
            this.setState({
              waiting: false
            });
          }
        })
        .catch((error) => {
          window.location = "https://teleterapia.pl";
          return (<Wait />);
        });;

      this.setState({
        keyaccess: keyaccess
      });
    },500);
  }

  viewKnowledgeExtension = (index) => {
    index = index.target ? index.target.id : index;
    
    let modal = this.state.modal;
    modal.show = true;
    
    index = index.replace("{", "").replace("}", "");
    let text = [];
    
    this.state.content.filter(k => k.type.indexOf(index) > -1 && k.type.indexOf("_title") === -1).sort((a, b) => {return parseInt(a.type.replace( /^\D+/g, '')) - parseInt(b.type.replace( /^\D+/g, ''))}).map(k => {
      text.push(<p key={k.id} dangerouslySetInnerHTML={ { __html: k.value }}></p>);
      return null;
    });

    modal.text = text;
    modal.title = (this.state.content.filter(k => k.type === index + "_title")[0].value);

    this.setState({
      modal: modal
    });
  }

  formatKnowledgeExtensions = (content, tb, te) => {
    var ret = [];
    let ai;
    let tagBegin = tb || "{r_";
    let tagFirstChar = tagBegin.substring(0, 1);
    let tagEnd = te || "_r}";
    
    while ((ai = content.indexOf(tagBegin)) > -1) {
        var indexBegin = (content.substring(ai, content.indexOf(tagEnd) + tagEnd.length));
        var indexEnd = indexBegin.replace(tagFirstChar, tagFirstChar + "/");
        if (ai > 0) {
          let c = content.substring(0, content.indexOf(indexBegin));
          c = this.formatKnowledgeExtensions(c, "<u", ">");
          ret.push(c);
          content = content.substring(content.indexOf(indexBegin));
        } else {
          let c = content.substring(content.indexOf(indexBegin), content.indexOf(indexEnd) + indexEnd.length).replace(indexBegin, "").replace(indexEnd, "");
          c = this.formatKnowledgeExtensions(c, "<u", ">");
          if (tagBegin === "<u") {
            ret.push(<u key={"u" + Math.random()}>{c}</u>);
          } else if (tagBegin === "{a_") {
            let u = this.state.content.filter(k => k.type === indexBegin.replace("{", "").replace("}", "") + "_href");
            let href = u && u[0] && u[0].value ? u[0].value : "#";
            if (href.indexOf("ak_lesson") > -1) {
              let lid = href.replace("/", "");
              href = href.replace(lid, this.state.content.filter(k => k.type === lid)[0].value);
            }
            ret.push(<a key={"a" + indexBegin} href={href} target={"_blank"} style={{fontStyle: (indexBegin.indexOf("_i_") > -1 ? "italic" : "")}}>{c}</a>);
          } else {
            ret.push(<b key={"b" + indexBegin} id={indexBegin} className={"knowledge-extend"} onClick={this.viewKnowledgeExtension}>{c}</b>);
          }
          content = content.substring(content.substring(content.indexOf(indexBegin), content.indexOf(indexEnd) + indexEnd.length).length);
        }
    }
    if (content.indexOf("<u") > -1) {
      content = this.formatKnowledgeExtensions(content, "<u", ">");
    }
    if (content.indexOf("{a_") > -1) {
      content = this.formatKnowledgeExtensions(content, "{a_", "}");
    }
    ret.push(content);
    return ret;
  }

  getContent = () => {
    var elements = [];
    var list = [];

    var content = this.state.content;
    for (var i = 0; i < content.length; i++) {
      var el = content[i];

      if (el.type === "quote") {
        elements.push(
          <React.Fragment key={el.id}>
            <blockquote className={el.classname} 
              dangerouslySetInnerHTML={ { __html: `<span class="begin">„</span>`+el.value+`<span class="end">„</span>` } }>
              
              
            </blockquote>
          </React.Fragment>
        );
      } else if (el.type === "block") {
        elements.push(
          <React.Fragment key={el.id}>
            <p className={el.classname}>{ this.formatKnowledgeExtensions(el.value) }</p> 
          </React.Fragment>
        );
      } else if (el.type === "video") {
        elements.push(
          <React.Fragment key={el.id}>
            <div className={el.classname}>
              <video style={{width: "80%", margin: "auto"}} controls><source src={el.value} type="video/mp4" />Your browser does not support HTML5 video.</video>
            </div>
          </React.Fragment>
        );
      } else if (el.type === "image") {
        elements.push(
          <React.Fragment key={el.id}>
            <img className={classnames("image", el.classname)} src={el.value} alt="img" />
          </React.Fragment>
        );
      } else if (el.type === "list-open") {
        list = [<li key={el.id} className={el.classname}>{this.formatKnowledgeExtensions(el.value)}</li>];
      } else if (el.type === "list") {
        list.push([<li key={el.id} className={el.classname}>{this.formatKnowledgeExtensions(el.value)}</li>]);
      } else if (el.type === "list-close") {
          list.push([<li key={el.id} className={el.classname}>{this.formatKnowledgeExtensions(el.value)}</li>]);
          elements.push(
            <React.Fragment key={el.id}>
              <ul className={el.classname}>
                {list}
              </ul>
            </React.Fragment>
          );
      } else if (el.type === "script") {
        switch (parseInt(el.value)) {
          case 3:
            elements.push(
              <React.Fragment key={el.id}>
                <Step3 params={ this.state.params }></Step3>
              </React.Fragment>
            )
            break;
            case 4:
              elements.push(
                <React.Fragment key={el.id}>
                  <Step4 params={ this.state.params }></Step4>
                </React.Fragment>
              )
            break;
            case 5:
              elements.push(
                <React.Fragment key={el.id}>
                  <Step5 params={ this.state.params }></Step5>
                </React.Fragment>
              )
                break;
              case 6:
                elements.push(
                  <React.Fragment key={el.id}>
                    <Step6 params={ this.state.params }></Step6>
                  </React.Fragment>
                )
                break;
                case 7:
                  elements.push(
                    <React.Fragment key={el.id}>
                      <Step7 params={ this.state.params }></Step7>
                    </React.Fragment>
                  )
                break;
                case 8:
                  elements.push(
                    <React.Fragment key={el.id}>
                      <Step8 params={ this.state.params }></Step8>
                    </React.Fragment>
                  )
                break;
                case 9:
                  elements.push(
                    <React.Fragment key={el.id}>
                      <Step9 params={ this.state.params }></Step9>
                    </React.Fragment>
                  )
                break;
                case 10:
                  elements.push(
                    <React.Fragment key={el.id}>
                      <Step10 viewKnowledgeExtension={this.viewKnowledgeExtension} params={ this.state.params }></Step10>
                    </React.Fragment>
                  )
                break;
                case 11:
                  elements.push(
                    <React.Fragment key={el.id}>
                      <Step11 params={ this.state.params }></Step11>
                    </React.Fragment>
                  )
                break;
                case 12:
                  elements.push(
                    <React.Fragment key={el.id}>
                      <Step12 params={ this.state.params }></Step12>
                    </React.Fragment>
                  )
                break;
                case 13:
                  elements.push(
                    <React.Fragment key={el.id}>
                      <Step13 params={ this.state.params }></Step13>
                    </React.Fragment>
                  )
                break;
                case 14:
                  elements.push(
                    <React.Fragment key={el.id}>
                      <Step14 params={ this.state.params }></Step14>
                    </React.Fragment>
                  )
                break;
                case 15:
                  elements.push(
                    <React.Fragment key={el.id}>
                      <Step15 params={ this.state.params }></Step15>
                    </React.Fragment>
                  )
                break;
                case 16:
                  elements.push(
                    <React.Fragment key={el.id}>
                      <Step16 logID={this.state.logID} params={ this.state.params }></Step16>
                    </React.Fragment>
                  )
                break;
              default:
                return;
        }
      } else if (el.type === "nextstep") {
        elements.push(
          <React.Fragment key={el.id}>              
              <ButtonOrderNext nextLessonAccessKey={this.state.nextLessonAccessKey} elClassname={el.classname} elText={el.value} refreshWhole={this.props.refreshWhole} logID={this.state.logID} nextLessonActivateDate={this.state.nextLessonActivateDate} />
          </React.Fragment>
        )
      }
    }
    

    return elements;
  }


  render() {
    if (this.state.waiting) {
      return <Wait withoutText={true} />;
    }
    
    return (
      <article className="article">
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onAction={this.onAction}
          throttle={30000}/>
        <Container>
          <h1>{this.state.title}</h1>
          {this.getContent()}

          <Modal show={this.state.modal.show} 
            onHide={this.handleModalClose} 
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className={"modal"}
            >
            <Modal.Header closeButton>
              <Modal.Title>{ this.state.modal.title }</Modal.Title>
            </Modal.Header>
            <Modal.Body>{this.state.modal.text}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleModalClose}>
                Zamknij
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      </article>
    );
  }
}

export default Article;

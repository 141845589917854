import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../resources/css/step12.css';
import classnames from 'classnames';



class Step12 extends React.Component {

  state = {
    answers: [],
    to: null
  }

  spanClick = (sentence) => {
    let caCount = 0;
    if (this.state.answers.indexOf(sentence) === -1) {
      let answers = this.state.answers;
      answers.push(sentence);
      if (this.props.params.correct1 === sentence || this.props.params.correct2 === sentence) {

        if (this.state.answers.indexOf(this.props.params.correct1) > -1) caCount++;
        if (this.state.answers.indexOf(this.props.params.correct2) > -1) caCount++;
        let alert = (caCount === 1 ? this.props.params.alert1 : this.props.params.alert2);
        this.setState({
          alert: alert
        });
        if (caCount < 2) {
          setTimeout(() => {
            if (this.state.co < 2) {
              this.setState({alert: null})
            }
        }, 5000);
        }
      }

      this.setState({
        answers: answers,
        co: caCount
      });
    }
  }

  getClass = (sentence) => {
    if (this.state.answers.indexOf(sentence) > -1) {
      if (this.props.params.correct1 === sentence || this.props.params.correct2 === sentence) {
        return "good";
      } else {
        return "bad";
      }
    }
    return null;
  }

  render() {
    return (
        <Container className={"exercise exercise12 my-5"}>
        <Row>
            <Col className={"title mb-1 mx-5 px-0"}>
              Ćwiczenie
            </Col>
          </Row>
          <Row>
            <Col className={"subtitle mx-5 px-3"}>
              { this.props.params.title }
            </Col>
          </Row>
          <Row className={"mt-3 mb-3"}>
            <Col className={"px-0"}>
              <blockquote style={{backgroundColor: "#ececec"}} className={"pt-4 px-5 mb-0 pb-5 text-justify"}>
                <img src="/img/quote.svg" className={"begin"} alt="quotebegin"/>
              {
                Object.keys(this.props.params).filter(p => p.indexOf("sentence") === 0).sort((a, b) => {return parseInt(a.replace( /^\D+/g, '')) - parseInt(b.replace( /^\D+/g, ''))}).map(p => {
                  if (this.props.params[p] === "br") {
                    return <React.Fragment key={p}><br/><br/></React.Fragment>
                  }
                  return (<span onClick={() => {this.spanClick(p)}} className={classnames("white", this.getClass(p))} key={p}>{this.props.params[p]} </span>)
                })
              }
                <img src="/img/quote.svg" className={"end"} alt="quotebegin"/>
                { <div className={classnames("alert")} style={{ opacity: (this.state.alert ? 1 : 0)}}>{this.state.alert}</div> }                
              </blockquote>
            </Col>
          </Row>
        </Container>
    );
  }
}

export default Step12;